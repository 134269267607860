.sc-jEACwC.isoBoxWrapper {
  border-radius: 0;
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.isoBoxWrapper .ViewContainer button {
  border-radius: 8px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: #535763;
  color: #ffffff !important;
  border-color: #535763 !important;
}
.isoBoxWrapper .ViewContainer button.active {
  border-color: #f80e46 !important;
}

._1nBOt {
  background-color: white !important;
  height: 64px !important;
}
._WuQ0f {
  padding: 10px 20px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 18px;
  color: #535763;
}
._2eZzQ {
  display: none;
}
._34SS0 {
  height: auto !important;
}
._3lLk3 {
  background-color: white !important;
  padding: 10px 20px;
  font-size: 12px;
  font-family: Inter;
  line-height: 20px;
  border-bottom: 1px solid #ebeff2;
  height: 64px;
}
._nI1Xw {
  color: #101828;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
}
._nI1Xw div:nth-child(1) {
  display: none;
}
._CZjuD > svg {
  height: 64px;
  background-color: white;
}

.calendar {
  height: 64px;
}
.gridBody .rows rect {
  height: 64px;
}
.calendar text {
  height: 64px;
  color: #535763;
  fill: #535763 !important;
}
._3_ygE {
  border-collapse: collapse;
}

.sc-jEACwC.isoBoxWrapper > div.sc-epALIP > div:nth-child(2) {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .cVKUjc > div:nth-child(2) ._3eULf {
    overflow-x: auto !important;
    background-color: white !important;
  }
  ._CZjuD {
    overflow: unset;
    background-color: white !;
  }
}
