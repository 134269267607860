@media screen and (max-width: 1140px) {
  .isoSignUpContent {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 1024px) {
  .isoSignUpPage {
    background: unset;
  }

  .isoSignUpContentWrapper {
    width: 100% !important;
  }

  .signup-form-main {
    max-width: unset !important;
  }
}

@media only screen and (max-width: 767px) {
  .isoSignUpContent {
    padding: 20px;
  }
}
