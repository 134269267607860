.vistors-card-icon > .sidebar-icon > svg > path {
  stroke: #D92550;
}
.assets-card-icon > .sidebar-icon > svg > path {
  stroke: #D92550;
}
.form-card-icon > .sidebar-icon > svg > path {
  stroke: #D92550;
}

.wethermap-mp > .bowlBQ {
  height: 425px;
}

.ant-card-body .bowlBQ .map-container {
  border-radius: 20px;
}

.wethermap-map .wether-map-p > .ant-card-body .bowlBQ {
  height: 415px;
}

.wethermap-mp .ant-card-body,
.standard-property-map > .ant-card-body {
  padding: unset;
}

.manage-property-category {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-auto-flow: column;
  gap: 20px;
}

.manage-property-category > div.wether-map-box {
  grid-row: span 3 / span 3;
}

.dashbord-btm-card-btn {
  background: #f9f9fb;
  border-radius: 8px;
  border: unset;
  width: 124px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.bottom-card span {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #171717;
  display: flex;
  justify-content: center;
  max-width: 112px;
}

.bottom-card {
  background: rgba(30, 31, 32, 0.05);
  border-radius: 24px;
  max-width: 263px;
  margin: 15px 0px 0px 0px !important;
}

.bottom-card > .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Weathers .weather-map-style > div  {
  height: 69vh ;
}
.property-map-style > div > div > div,

.weather-map-style > div > div  {
  border-radius: 20px;
}
.propertyMap{
  height: 69vh !important;
}
/* .manage-property-main {
  padding-left: 30px;
  padding-right: 30px;
} */

.manage-property-cards {
  
  /* padding-top: 30px; */
  padding-bottom: 20px;
  display: flex;
  gap: 14px;
}
.manage-property-cards .dashbord-box {
  /* max-width: 255px; */
 
  height: 115px;
  border-radius: 10px;
  /* background-color: #dadada; */
  background-color: #ffffff;
  /* border: 1px solid #000000; */
  width: 100%;
}
.manage-property-cards .dashbord-box .count-btn {
  display: flex;
  align-items: center;
  padding-top: 18px;
  justify-content: space-between;
}
.manage-property-cards .dashbord-box .count-btn h2 {
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
}
.manage-property-cards .dashbord-box .count-btn button {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  padding: 10px 18px;
  cursor: pointer;
}
.manage-property-cards .dashbord-box h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
}
.manage-property-cards .dashbord-box:nth-child(1) .sidebar-icon svg path {
  stroke: rgb(248, 14, 70);
}
.manage-property-cards .dashbord-box:nth-child(2) .sidebar-icon svg path {
  stroke: rgb(248, 14, 70);
}
.manage-property-cards .dashbord-box:nth-child(3) .sidebar-icon svg path {
  stroke: rgb(248, 14, 70);
}
.manage-property-cards .dashbord-box:nth-child(4) .sidebar-icon svg path {
  stroke: rgb(248, 14, 70);
}
.mob-icon-menu {
  display: none;
}
.upperIcon{
  display: flex;
width: 32px;
height: 22.675px;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
.upperCatName{
  margin-left: 10px;
  color: #686868;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.catBtn{
 
  display: flex;
  margin-left: 40px;
width: 32px;
height: 22.675px;
text-align: center;
justify-content: center;
align-items: center;
flex-shrink: 0;
}
.btn-design {
  width: 49px;
  border-radius: 10px;
  align-items: center !important;
  display: flex !important;
  padding: 0px !important;
  justify-content: center;
}

.btn-design .ant-card-body::before,.btn-design .ant-card-body::after {
  display: none;
}

@media only screen and (max-width: 991px) {
  .property-map-style {
    height: 600px;
  }
  .manage-property-cards {
    flex-wrap: wrap;
  }
  .manage-property-cards .dashbord-box {
    max-width: unset;
    width: 49%;
  }
}
/* main.ant-layout-content.isomorphicContent {
  flex: none !important;
} */
@media only screen and (max-width: 840px) {
  .manage-property-cards .dashbord-box {
    width: 48%;
  }
}
@media only screen and (min-width: 768px) {
  header.isomorphicTopbar .isoLeft .triggerBtn {
    display: none !important;
  }
  .category-card{
    margin-bottom: 15px !important;
  }
  /* .propertyMap{

  margin-top: 20px;
  } */
}
@media only screen and (max-width: 767px) {
  .manage-property-main {
    padding-left: 0;
  }
  .isoLogoWrapper h3 {
    text-align: left;
  }
}
@media only screen and (max-width: 1440px) {
.propertyMap{
  height: 64vh !important;
}
.weather-map-style > div  {
  height: 64vh !important;
}

}
@media only screen and (max-width: 575px) {
  .manage-property-cards .dashbord-box {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .bottom-card{
    max-width: 100% !important;
  }
  .bottom-card span {
    max-width: 100% !important;
  }
  .category-card{
    margin-bottom: 15px !important;
  }
}
@media only screen and (max-width: 480px) {
  .iUsNgI.sidebar-wp .isomorphicSidebar {
    width: 375px !important;
    min-width: unset !important;
    max-width: 375px !important;
  }
  .propertyMap{

    margin-top: 20px ;
    }
  .fdviJk .isomorphicTopbar {
    margin-left: 375px !important;
  }
  .fdviJk .isomorphicTopbar.collapsed {
    margin-left: 0 !important;
  }
  .des-icon-menu {
    display: none;
  }
  .mob-icon-menu {
    display: block;
  }

  .sidebar-wp .triggerBtn {
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 999;
  }
}
