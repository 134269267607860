.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane {
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  margin-left: 8px;
  margin-right: 8px;
  min-width: 264px;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane>header>span {
  color: #1f2633;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane>header>div>a {
  transform: rotate(90deg);
  color: #c7ced9;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane .react-trello-card {
  box-shadow: none;
  background-color: #f3f3f3;
  border: none;
  margin-right: 0;
  max-width: 264px;
  padding: 20px;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane .react-trello-card header span {
  color: #1f2633;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane .react-trello-card header span:before {
  display: none;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane>div>a {
  background-color: #f3f3f3;
  padding: 6px;
  text-align: center;
  color: transparent;
  position: relative;
  border-radius: 8px;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane>div>a::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../../../../assets/images/add-task.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.ant-tabs-tabpane .isoBoxWrapper .smooth-dnd-container>section {
  min-width: 264px;
  background-color: transparent;
}

.ant-tabs-tabpane .isoBoxWrapper .smooth-dnd-container section>button {
  background-color: #f3f3f3;
  position: relative;
  color: transparent;
  border-radius: 8px;
}

.ant-tabs-tabpane .isoBoxWrapper .smooth-dnd-container section>button::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../../../../assets/images/add-task.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.ant-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active,

.ant-tabs {
  color: #98a2b2;
}

.ant-tabs-tab-btn {
  font-size: 12px;
  font-family: Inter;
  font-weight: 700;
  line-height: 20px;
}

._2B2zv svg .content .bar g {
  height: 16px;
}

._2B2zv svg .content .bar g text {
  display: none;
}

._2B2zv svg .content .bar g g rect {
  height: 16px;
}

._35nLX {
  stroke-width: 0;
}

._CZjuD>svg {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  border-left: 0;
}

._34SS0:nth-of-type(even) {
  background-color: #ffffff;
}

._2dZTy:nth-child(even) {
  fill: #ffffff;
}

._2B2zv svg .ticks {
  display: none;
}

.sc-YysOf .ant-tabs>.ant-tabs-nav {
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 210px;
  z-index: 999;
}

@media only screen and (max-width: 640px) {
  .ant-tabs {
    position: relative;
  }

  .sc-YysOf .ant-tabs>.ant-tabs-nav {
    top: 0 !important;
  }

  .cINOXn {
    padding-top: 80px !important;
  }

  main>.cINOXn.isoLayoutContentWrapper {
    padding: 0 !important;
  }
}

textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.task_box.custom-task-board .smooth-dnd-draggable-wrapper article .sc-bdOgaJ {
  justify-content: start;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane .react-trello-card header span:nth-child(2) {
  display: none;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane header textarea {
  overflow: hidden hidden !important;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane header text {
  overflow: hidden hidden !important;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane .smooth-dnd-draggable-wrapper article header textarea {
  overflow: hidden hidden !important;
}

.ant-tabs-tabpane .isoBoxWrapper .react-trello-lane .smooth-dnd-draggable-wrapper article>div textarea {
  overflow: hidden hidden !important;
}

/*  */
.sc-bdOgaJ {
  background-image: url('./image/file_present.png') !important;
  background-repeat: no-repeat !important;
  text-align: right;
  background-position: 87% 15%;
}

.sc-bdOgaJ>textarea {
  padding-left: 8px;
  padding-top: 3px;
  font-size: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  color: #9333EA;
}

/* Avatar (1) */
/* sc-dWZqqJ igFBTX */
/* .sc-dWZqqJ:before {
  content: '12';
  position: absolute;
  left: 60px;
  top: 6px;
  color: red;
  font-size: 15px;
} */
.sc-bdOgaJ:after {
  content: 12;
  position: absolute;
  left: 60px;
  top: 6px;
  color: red;
  font-size: 15px;
}

/* .sc-bdOgaJ.iUdxYS {
  background-image: url('./image/Avatar (1).png') !important;
  background-repeat: no-repeat !important;
  text-align: right;
  background-position: 10% 15%;
  content: "fgdfgd";
} */

.sc-dWZqqJ>textarea {
  padding-left: 8px;
  padding-top: 3px;
  font-size: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  color: #9333EA;
}

.iUdxYS textarea.sc-dWZqqJ.igFBTX {
  content: "10";
  overflow-x: hidden;
  overflow-wrap: break-word;
  height: 19px;
  background-image: url('./image/file_present.png') !important;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFuSURBVHgBtZRRTsJAEIZntk0Uo6I3KCcAb4AnECNQeeMGcgPhBHoD9UVQMPEG1BvUG/QIJKKlwe44W0JiCV22Ef+kaXY78+3MzkwBtixc3ejXP6qA6Ky1JgpaowMPTIH9xrSNYN1pHRC67nOhl/VdpCPAKmhPR5+IOk/N8NoMuEEE5AuQpzqoCTBg1GS5aA73tVAtEKU4vxwWSuE8KvHS45Rr6p5jgAqiuCeC7sD96vz2sTNpBJ77svP6ePFZi+I9j2jWA6Txsmgc4cJOYtksQlSpJlWtHQoo8tEBGChXUViBgPiE4/O3BfRUUTjd9z8DZWwdmdjZJkbfdnRrg+RCYNI+Aq0i92RuYHXxUu1B42ROuI3UDpF0VLXWSZeyo3qsNSp44Xz3WD2qjQbu7IphlSwnfcoSbwaNsEw0fZCIE56MM5LU1bmY3GEb0W5boFLdbJxKmafAh5ziM97SjBX16yH/YGMHjGhWoO4Y/lM/YmSUM0rGo7oAAAAASUVORK5CYII=) !important; */
  background-repeat: no-repeat !important;
  text-align: right;
  background-position: 87% 15%;
}


/* .iUdxYS textarea.sc-dWZqqJ.igFBTX > template{
  padding-left: 8px;
  padding-top: 3px;
  font-size: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  color: #9333EA;
} */
.sc-dWZqqJ.igFBTX {
  content: "10";
  overflow-x: hidden;
  overflow-wrap: break-word;
  height: 19px;
  background-image: url('./image/task.svg') !important;
  background-repeat: no-repeat !important;

  background-position: 90% 50%;
}

.dtyQOe {
  width: 99% !important;
}

.header.sc-eulNck.sc-dNsVcS.fTqCqV.iNmfuF {
  order: 1;
  width: 100%;
}

.sc-empnci {
  /* justify-content: flex-start !important; */
}

.sc-bdOgaJ.iUdxYS {
  order: 3;
  width: 100%;
}

.sc-empnci.eBZhbo {
  order: 2;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}

article.sc-knuQbY.sc-ERObt.HKEIr.jqIyEB.react-trello-card {
  display: flex;
  flex-wrap: wrap;
}

header.sc-eulNck.sc-dNsVcS.fTqCqV.iNmfuF {
  width: 100%;
  border: 0;
  margin: 0;
}

.sc-empnci.eBZhbo span:nth-child(1) {
  background: #e4e9ef !important;
  color: #606C80 !important;
}

.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #F3F3F3;
  margin-bottom: 10px;
  width: 280px;
  /* Shadows/Gray/1 Level */
  box-shadow: 0px 1px 3px 0px rgba(96, 108, 128, 0.05);
  gap: 12px;
}

.header {
  display: flex;
  width: 240px;
  justify-content: space-between;
  align-items: flex-start;
}

.title1 {
  color: var(--gray-800, #1F2633) !important;

  /* Semibold/12 */
  font-family: Inter;
  font-size: 12px;
  max-width: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.span1 {
  display: flex;
  width: 124px;
  margin-left: 80px;
  justify-content: space-between;
  align-items: flex-start;
}

.lblFileCount {
  color: var(--sky-600, #17A5E6);

  /* Extra Bold/10 */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
}

.mainDivCard {
  border-radius: 8px;
  /* background:  #F3F3F3; */

  /* Shadows/Gray/1 Level */
  /* box-shadow: 0px 1px 3px 0px rgba(96, 108, 128, 0.05); */
  display: flex;
  padding: 10px;
  width: 240px;
  flex-direction: row;
  /* flex-direction: column; */
  align-items: flex-start;
  gap: 12px;
}

/* .tagTitle {
  font-size: small;
  display: flex;
  height: 20px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--gray-50, #EBEEF2);
  background: #F3F3F3;
} */

.mainDivCard .tagTitle:nth-child(2) {
  background-color: #3B82F61A;
  color: #2563EB;
}

.mainDivCard .tagTitle:nth-child(4) {
  background: #e4e9ef !important;
  color: #606C80 !important;
}

.mainDivCard .tagTitle:nth-child(3) {
  background: #EAB3081A 10% !important;
  color: #CA8A04 !important;
}

.mainDivCard .tagTitle:nth-child(5) {
  background: #3B82F61A !important;
  color: #2563EB !important;
}

.mainDivCard .tagTitle:nth-child(6) {
  background: #A855F71A !important;
  color: #9333EA !important;
}

.mainDivCard .tagTitle:nth-child(7) {
  background: #EC48991A !important;
  color: #DB2777 !important;
}

.mainDivCard .tagTitle:nth-child(8) {
  background: #22C55E1A !important;
  color: #16A34A !important;
}

.mainDivCard .tagTitle:nth-child(9) {
  background: #EAB3081A !important;
  color: #CA8A04 !important;
}

.mainDivCard .tagTitle:nth-child(10) {
  background: #A858F71A !important;
  color: #9373EA !important;
}

.mainDivCard .tagTitle:nth-child(11) {
  background: #EAB3081A !important;
  color: #CA8A04 !important;
}

.divImgAvtar {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  width: 240px;
  margin-left: 15px;
  border-radius: 24px;
}

.avtar {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
  /* gap: 10px; */
}

.fileContainer{
  display: flex;
    width: 124px;
    justify-content: space-between;
    align-items: center
}
.imgLng {
  color: var(--purple-600, #9333EA);

  /* Extra Bold/10 */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
}

/* 13/7 strat */
.cust-todo-task .custom-task-board {
  width: 100% !important;
}

.cust-todo-task .custom-task-board > div {
  width: 100%;
}

.cust-todo-task .custom-task-board > div > .smooth-dnd-container {
  display: flex;
  width: 100%;
  gap: 16px;
flex-wrap: wrap;
}

.cust-todo-task .custom-task-board > div > .smooth-dnd-container > .smooth-dnd-draggable-wrapper {
  max-width: calc(25% - 16px);
  flex: 0 0 calc(25% - 16px);
}

.cust-todo-task .custom-task-board > div > .smooth-dnd-container > .smooth-dnd-draggable-wrapper > section.react-trello-lane {
  width: 100% !important;
  margin-inline: 0;
  min-width: auto;
}

.cust-todo-task .custom-task-board > div > .smooth-dnd-container > .smooth-dnd-draggable-wrapper > section.react-trello-lane > header + div {
  width: 100%;
  min-width: auto;
}

.cust-todo-task .custom-task-board .cust-todo-task-box {
  margin-inline: 0;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.cust-todo-task .custom-task-board .cust-todo-task-box img.cust-delete-btn {
    display: block;
    margin-right: 0;
    margin-left: auto;
    position: absolute;
    top: 10px;
    right: 10px;
    rotate: 130deg;
}

.cust-todo-task-box img.cust-delete-btn {
  display: block;
  margin-right: 0;
  margin-left: auto;
  position: absolute;
  top: -10px;
  right: -10px;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
}
.cust-todo-task .custom-task-board .cust-todo-task-box:hover img.cust-delete-btn{
  opacity: 1;
  
}

.cust-todo-task .custom-task-board .cust-todo-task-box .divImgAvtar {
  align-items: center;
}

.cust-todo-task .custom-task-board .cust-todo-task-box .divImgAvtar span.avatars-count {
  width: 32px;
  height: 32px;
  background-color:var(--gray-30, #F2F4F7);;
  border-radius: 50%;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -18px;
  color: var(--gray-500, #606C80);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* Extra Bold/10 */
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 800;
line-height: 16px; /* 160% */
}

.cust-todo-task .custom-task-board .cust-todo-task-box .divImgAvtar span.add-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  flex: 0 0 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px dashed var(--gray-50, #EBEEF2);
  background: #F3F3F3;
  margin: auto;
}

.cust-add-form {
  border: none !important;
  width: 100%;
height: 250px;
flex-shrink: 0;
border-radius: 10px ;
background: #F3F3F3 !important;
}
.cust-add-form .addassign{
  color: #525252;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px; /* 116.667% */
}
.cust-add-form .addBtn{

  color: white !important;
  width: 100%;
height: 36px;
flex-shrink: 0;
border-radius: 10px;
background: rgb(248, 14, 70) !important;
}
.cust-add-form .assignImg{
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.cust-add-form .addfiels{
  padding: 5px !important;
  width: 100% !important;
height: 36px !important;
flex-shrink: 0 !important;
border-radius: 10px !important;
border: 1px solid #EDEDED !important;
background: #FFF !important;
}
.cust-todo-task .custom-task-board .cust-todo-task-box header.header,
.cust-todo-task .custom-task-board .cust-todo-task-box .mainDivCard {
  width: 100%;
}

.cust-todo-task .custom-task-board .cust-todo-task-box header.header {
  margin-bottom: 11px;
}

.cust-todo-task .custom-task-board .cust-todo-task-box img.cust-delete-btn + div,
.cust-todo-task .custom-task-board .cust-todo-task-box .divImgAvtar {
  width: 100%;
}

.cust-todo-task .custom-task-board .cust-todo-task-box .mainDivCard {
  padding-inline: 0;
  gap: 8px;
}

.cust-todo-task .cust-todo-task-box .tagTitle{
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px;
  padding: 8px 10px;
  border-radius: 16px;
}

.cust-todo-task .cust-todo-task-box .tagId{
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px;
  padding: 8px 10px;
  border-radius: 16px;
  background-color: #EBEEF2;
  color: #606C80;
}
.addCard{
  background-color: #f3f3f3;
    padding: 6px;
    text-align: center;
    color: transparent;
    position: relative;
    border-radius: 8px;
}
.cINOXn {
  padding: 0 !important;
}
section.ant-layout.isoContentMainLayout main.ant-layout-content.isomorphicContent {
  /* padding-inline: 29px !important; */
  margin-top: 30px;
  /* margin-bottom: 20px; */
}

@media (min-width: 768px) and (max-width: 1024px){
.cust-todo-task .custom-task-board > div > .smooth-dnd-container > .smooth-dnd-draggable-wrapper {
      max-width: calc(50% - 16px);
      flex: 0 0 calc(50% - 16px);
}
}

@media (max-width: 767px){
.cust-todo-task .custom-task-board > div > .smooth-dnd-container > .smooth-dnd-draggable-wrapper {
      max-width: 100%;
      flex: 0 0 100%;
}
}
/* 13/7 end */


/* CustomAddCardForm.css */

.custom-add-card-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-add-card-form input[type="text"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-add-card-form button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-add-card-form button:hover {
  background-color: #0056b3;
}
/*21-08-23 css start*/
/* section.ant-layout.isoContentMainLayout main.ant-layout-content.isomorphicContent {
  padding: 70px 15px 30px 15px !important;
} */
/*21-08-23 css end*/