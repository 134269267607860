@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.mpList-icon > span {
  display: flex;
  gap: 20px;
  align-items: center;
}

span.anticon.anticon-ellipsis {
  rotate: 90deg;
}
.modal-title-style .ant-modal-header {
  border-radius: 20px 20px 0 0;
  padding: 22px 30px;
}
.modal-title-style .ant-modal-content {
  border-radius: 20px;
}
.modal-title-style .ant-modal-header .ant-modal-title,
.modal-title-style form .form-title h3 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
}
.modal-title-style form .form-body label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
}
.modal-title-style form .form-body input.ant-input {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 12px;
}
.modal-title-style form .form-body input.ant-input::placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.ant-timeline-item-head.ant-timeline-item-head-green {
  color: red !important;
  border-color: red !important;
}
.modal-title-style form .form-body input.ant-input,
.modal-title-style form .form-body .ant-select-selector {
  background: #ffffff;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px !important;
  padding: 8px 12px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.modal-title-style form .form-body input.ant-input.PicInput {
  border-right: 0px !important;
  border-radius: 8px 0px 0px 8px !important;
}
/* .ant-popover.ant-popover-placement-bottomRight {
  z-index: 1;
} */
.modal-title-style button.ant-modal-close span.anticon svg path {
  fill: #f80e46;
}
.modal-title-style button.ant-modal-close {
  height: 64px;
  display: flex;
  align-items: center;
}
.modal-title-style button.ant-modal-close span.anticon.anticon-close {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #f80e46;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
span.ant-modal-close-x {
  justify-content: center;
  display: flex;
  align-items: center;
}
.modal-title-style button.ant-modal-close span.anticon svg {
  font-size: 12px;
}
.modal-title-style form .form-body .ant-row:nth-child(4) {
  flex-direction: column;
}
.modal-title-style form .form-body .ant-row:nth-child(4) > .ant-col {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}
.modal-title-style
  form
  .form-body
  .ant-row:nth-child(4)
  button#addProperty_authenticate {
  width: 100%;
}
.modal-title-style form .form-title {
  background-color: #3f3f44;

}
.modal-title-style form label.checkbox-contact span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
}
.modal-title-style
  form
  .ant-col.ant-form-item-control
  label.ant-radio-wrapper-in-form-item
  span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
}
.modal-title-style .ant-modal-footer {
  display: flex;
}
.modal-title-style .ant-modal-footer button.ant-btn {
  width: 50%;
  margin: 0 10px;
  padding: 8px 12px;
  height: auto;
  background-color: #535763 !important;
  border: none;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.modal-title-style .ant-modal-footer button.ant-btn:hover {
  background-color: #3f3f44 !important;
}
.modal-title-style .ant-modal-footer {
  display: flex;
  padding: 16px 30px 24px 30px;
  border-top: 1px solid #dedede;
  margin: 0 -10px;
}
.modal-title-style {
  overflow: hidden;
}

.modal-title-style form .form-body input.ant-input::placeholder {
  color: rgba(63, 63, 68, 0.3);
}

.ant-modal-content .ant-modal-header {
  background-color: rgb(63, 63, 68);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  color: #ffffff;
}
.ant-modal-content .ant-modal-close .ant-modal-close-x span {
  border: 1.5px solid rgb(217, 37, 80);
  border-radius: 50%;
  padding: 5px;
}
.ant-modal-content .ant-modal-close .ant-modal-close-x span svg {
  width: 10px;
  height: 10px;
}
.ant-modal-content .ant-modal-close {
  color: rgb(217, 37, 80);
}
.ant-modal-content .ant-modal-body .ant-form-item-control-input-content input {
  border: 1px solid rgb(208, 213, 221) !important;
}
.ant-modal-content .ant-modal-body .ant-picker-input input {
  border: none !important;
}
.ant-modal-content .ant-modal-body .ant-picker {
  border: 1px solid rgb(208, 213, 221) !important;
}
.ant-modal-content .ant-modal-body .ant-select-selector {
  border: 1px solid rgb(208, 213, 221) !important;
}
/* form scroll */
.ant-modal-content .ant-modal-body .ant-form .ant-form-item #form_ {
  overflow: unset !important;
}
.ant-modal-content .ant-modal-footer .cancel-button {
  width: 50%;
  background-color: rgb(63, 63, 68);
  padding: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  text-align: center;
  height: auto;
  border: transparent;
}
.ant-modal-content .ant-modal-footer .cancel-button:hover {
  color: #ffffff;
  border-color: transparent;
}
.ant-modal-content .ant-modal-footer .btn-success {
  width: 50%;
  background-color: #f80e46;
  padding: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: rgb(255, 255, 255) !important;
  text-align: center;
  height: auto;
  border: transparent;
}
.ant-modal-content .ant-modal-footer .btn-success:hover {
  color: #ffffff;
  border-color: transparent;
}

.user-access-modal .ant-form .btn-create-crop{
  background-color:#f80e46;
  border-color: #f80e46;
  padding: 8px;
  font-size: 12px;
  height: auto;
  border-radius: 8px;
}
.ant-timeline .ant-timeline-item-content .delete-button{
  width: auto;
  background-color: transparent;
  padding: 0;
}
.ant-card.ant-card-bordered {
  display: inline-block;
  width: 100%;
  
}
.user-access-modal .ant-tabs-tabpane .btn-create-crop{
  background-color:#f80e46;
  border-color: #f80e46;
  padding: 8px;
  font-size: 12px;
  height: auto;
  border-radius: 8px;
}
.ant-modal.user-access-modal .ant-modal-content .ant-modal-body .btn-summary-action .ant-btn{
  background-color: #f80e46;
  border-color: #f80e46;
}
.ant-modal.user-access-modal .ant-modal-content .ant-modal-body .ant-statistic .ant-statistic-content-value{
  color: #f80e46;
}
.ant-modal.user-access-modal .ant-modal-content .ant-modal-body .react-collapsible-theme table thead span svg{
  fill:#f80e46;
}
.ant-modal.user-access-modal .ant-modal-content .ant-modal-body .react-collapsible-theme table tbody .created_at svg{
  fill:#f80e46;
}

button.ant-btn.ant-btn-primary.btn-create-breed{
  background-color:#f80e46;
  border-color: #f80e46;
  padding: 8px;
  font-size: 12px;
  height: auto;
  border-radius: 8px;
}
.ant-input-number{
  width: 100%;
}
.ant-modal-content .ant-modal-body .ant-form-item-control-input-content .ant-select-selection-search input{
  border: none !important;
}

.ant-modal-body .form-body .btn-create-feeding-type{
  background-color:#f80e46;
  border-color: #f80e46;
  padding: 8px;
  font-size: 12px;
  height: auto;
  border-radius: 8px;
}
.react-collapsible-theme .react-collapsible{
  overflow: hidden;
}
body #root .react-collapsible-theme .react-collapsible thead > tr > th span{
  display: inline;
}
.react-collapsible-theme .react-collapsible tbody > tr > td {
  color: #475467;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.sc-cPiKLX .area-manage-table .react-collapsible-theme .react-collapsible{
  box-shadow: none;
  border-radius: 0 !important;
}
.react-collapsible-theme .react-collapsible tbody > tr > td.timelog{
  color: #101828;
  font-size: 14px;
  font-weight: 500;
}
.ant-modal-content .ant-modal-body form .form-body .ant-row .ant-btn.btn-success{
  background-color:#f80e46;
  border-color: #f80e46;
  padding: 8px;
  color: white;
  font-size: 12px;
  height: auto;
  border-radius: 8px;
}
.ant-popover .ant-popover-content .ant-popover-arrow{
  opacity: 0;
}
.sc-cPiKLX .manage-table .react-collapsible-theme .react-collapsible{
  border-radius: 0 !important;
  box-shadow: none !important;
}