.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ant-pagination {
  margin: 0;
  gap: 5px;
}

.ant-pagination-item {
  border: none;
  background: transparent;
  margin: 0;
  font-size: 16px;
  
  transition: background-color 0.3s;
}

.ant-pagination-item-active {
  background-color: rgb(248, 14, 70);
  border-color: rgb(248, 14, 70);
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item:hover {
  background-color: #f0f0f0;
}

li.ant-pagination-next {
  margin-left: 10px;
}

.ant-pagination-prev,
.ant-pagination-next {
  border: none;
  background: transparent;
  font-size: 16px;
}

.ant-pagination-prev hover {
  border-color: #d9d9d9 !important;
}

.ant-pagination-next :hover {
  border-color: #d9d9d9 !important;
}

.ant-pagination-prev-icon,
.ant-pagination-next-icon {
  font-size: 20px;
}

span.anticon.anticon-right hover {
  color: #d9d9d9;
}

span.anticon.anticon-left hover {
  color: #d9d9d9;
}

.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgb(248, 14, 70);
}

.ant-select-selection-item {
  font-size: 14px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

/* .ant-select-selector {
    border:1px solid #d9d9d9 !important;
  } */

.loader {
  position: absolute !important;
  top: 50% !important;
  bottom: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 auto !important;
}