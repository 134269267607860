/* .dailyEventWrapper{
  color: black !important;
} */
.modeWrapper{

  text-align: end !important;
}
.awesome-calendar-header {
  background-color: #f0f0f0;
}
.calendarHeader h1{
  font-size: 1.5em;
}
/* .awesome-calendar-header h1{
  font-size: 1em
} */
/* .ant-tabs-content{
  border: none ;
  padding: 20px !important;
  background-color: white !important;
}  */
 .modeButton {
  /* border-radius: 8px !important; */
  color: white !important;
    margin-left: 10px !important;
    background-color: #535763 !important;
    border-radius: 8px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-color: #535763 !important;
}
.modeButtonActive{
  color: white !important;
  margin-left: 10px !important;
  border-radius: 8px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: rgb(248, 14, 70) !important;
  border-color: rgb(248, 14, 70) !important;;
}
.mainDiv {
  background-color: white;
  border: none;
  padding: 20px;
  border-radius: 8px;
  
}
.yearlyMonth {
  float: inherit !important;
  background-color: white !important;
}