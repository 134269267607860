/*Styles for the body of the table */

.react-collapsible-theme table.react-collapsible {
    border-collapse: collapse;
    width: 100%;
}

.react-collapsible-theme .react-collapsible thead,
.react-collapsible-theme .react-collapsible .child-label {
    /*font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: #555;
    text-align: left;
    font-weight: 600;*/
}

.react-collapsible-theme .react-collapsible thead > tr > th
{
  color: #788195;
  font-size: 13px;
  background-color: #f1f3f6;
  border-bottom: 0;
  padding: 16px;
}
.react-collapsible-theme .react-collapsible thead th,
.react-collapsible-theme .react-collapsible td {
    /*min-width: 10rem;
    padding-bottom: .8rem;*/
    padding: 16px;
}


.react-collapsible-theme .react-collapsible .clickable {
    cursor: pointer;
}

.react-collapsible-theme .react-collapsible tr {
    border-bottom: 0.1rem #e0e4ea solid;
}

.react-collapsible-theme .react-collapsible td {
    /*padding: .8rem .8rem .8rem 0;
    font-size: 1.4rem;
    font-weight: 200;*/
    font-size: 12px;
}


/*child row in table */

.react-collapsible-theme .react-collapsible .child-cell {
    display: flex;
}

.react-collapsible-theme .react-collapsible .child-label {
    flex-basis: 25%;
    min-width: 100px;
}

.react-collapsible-theme .react-collapsible .child-content {
    flex-basis: 75%;
}

@media screen and (min-width: 768px) {
    .react-collapsible-theme .react-collapsible .child-label {
        flex-basis: 10%;
    }
    .react-collapsible-theme .react-collapsible .child-content {
        flex-basis: 90%;
    }
}


/*Styles for search */

.react-collapsible-theme .react-collapsible-search {
    margin-top: 5px;
    margin-bottom: 25px;
}

.react-collapsible-theme .react-collapsible-search .react-collapsible-clear {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
}

.react-collapsible-theme .react-collapsible-search input {
    border: 0;
    border-bottom: 1px #009cde solid;
    width: 100%;
    height: 2rem;
    font-size: 1.4rem;
    font-weight: 200;
}

.react-collapsible-theme .react-collapsible-search input::placeholder {
    color: #858DA3;
}

.react-collapsible-theme .react-collapsible-search .search-input {
    display: inline-block;
    position: relative;
}

.react-collapsible-theme .react-collapsible-search .search-input .search-icon {
    position: absolute;
    right: -2px;
    top: 0;
}

.react-collapsible-theme .brand-primary-light {
    color: #f80e46;
}

.react-collapsible-theme .search-highlight {
    background-color: #C1C9D5;
}


/*Styles for pagination */

.react-collapsible-theme .react-collapsible-page {
    font-size: 1.4rem;
    font-weight: 200;
}

.react-collapsible-theme .react-collapsible-page .arrow-left {
    margin-right: .8rem;
}

.react-collapsible-theme .react-collapsible-page .arrow-right {
    margin-left: .8rem;
}

.react-collapsible-theme .highlight {
    background-color: #FFFF00
}

.expand-cls {
  float: left;
  margin-right: 5px;
}

.no-title.child-label {
  display: none;
}