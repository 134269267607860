.layerWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.layerWrap .leaflet-container {
  height: 100%;
}
.layerWrap .leaflet-interactive.leaflet-edit-marker-selected {
  margin-top: 0 !important;
  margin-left: 0 !important;
}
.layerWrap .leaflet-retina .leaflet-draw-toolbar a.leaflet-draw-draw-marker {
  background: #fff;
  position: relative;
}
.layerWrap
  .leaflet-retina
  .leaflet-draw-toolbar
  a.leaflet-draw-draw-marker:after {
  content: "T";
  position: absolute;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.layerWrap .leaflet-bar a.leaflet-draw-draw-marker::after {
  position: absolute;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "T";
}

.layerWrap .leaflet-bar a.leaflet-draw-draw-marker {
  background: white;
  position: relative;
}
.deleteLayer .btn-success {
  background: #f80e46 !important;
}
.deleteLayer .btn-success:hover {
  border-color: #f80e46 !important;
}
