.hazardHeader{
    margin-bottom: 20px;
    width: 100%;
    color: white;
height: 64px;
flex-shrink: 0;
border-radius: 20px 20px 0px 0px !important;
background: #3F3F44;
}
.headerTitle{
    padding:  20px;
    color: #FFF;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 111.111% */
}
.hazardLabel{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
}
.ctmForm{
    padding: 10px;
}

/* .addHazards{
    width: 100%;
    background-color: rgb(63, 63, 68);
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  } */
  .attachLocationBtn{
    width: 100%;
    background-color: #8F9497;
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    text-align: center;
    height: auto;
    border: transparent;
  }
  .csttextbox{
    border-radius: 8px;
border: 1px solid var(--gray-300, #D0D5DD);
background: var(--base-white, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }