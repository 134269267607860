@media screen and (max-width: 1200px) {
  .signin-heading,
  .signin-heading-hi {
    font-size: 30px !important;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1024px) {
  /* .isoSignInPage {
    background: unset;
  } */

  .isoLoginContentWrapper {
    width: 100% !important;
  }
}
