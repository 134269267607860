.confirm-permission-modal .ant-btn-primary {
  background: #f80e46;
  border-color: #f80e46;
}
.confirm-permission-modal .ant-btn:hover {
  border-color: #f80e46;
  color: #f80e46;
}
.confirm-permission-modal .ant-btn-primary:hover {
  color: #ffffff;
}
