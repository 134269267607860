@media only screen and(max-width:767px){
  #weather_tab_data {
    width: 100%;
    overflow-x: auto;
  }
  #chart_weather{
    width: 100%;
    overflow-x: auto;
  }
  .statistic-card .sc-cMa-dbN{
    height: auto;
  }
  .weather-chart-wrap{
    overflow: scroll;
  }
}
 .ant-popover-inner-content{
    width:100%;
}
